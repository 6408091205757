@charset "UTF-8";

$iconFb: "\e901";
$iconTw: "\e902";
$iconIn: "\e900";

$colorRed: #af3333;


// #clearfix
@mixin clearfix {
  &:after {
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    font-size: 0;
    content: '.';
  }
}

@mixin icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-instagram:before {
  content: "\e900";
}

.icon-brand:before {
  content: "\e900";
}

.icon-social:before {
  content: "\e900";
}

.icon-facebook:before {
  content: "\e901";
}

.icon-brand2:before {
  content: "\e901";
}

.icon-social2:before {
  content: "\e901";
}

.icon-twitter:before {
  content: "\e902";
}

.icon-brand3:before {
  content: "\e902";
}


.icon-social3:before {
  content: "\e902";
}

.icon-expand_more:before {
  content: "\e5cf";
}
